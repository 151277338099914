<template>
    <v-app class="bg-login">
        <v-main>
            <v-container fill-height>
                <v-flex xs12 md12 lg10 xl8 class="mx-auto card-wrapper">
                    <v-card rounded="lg" style="z-index: 100">
                        <div
                          class="d-flex justify-space-betweeen flex-column flex-md-row"
                          style="min-height: 650px"
                        >
                            <!-- Inicia contenido lado izquierdo -->
                            <div class="px-4 py-4 py-my-0 px-sm-8 px-md-16 flex-shrink-1 flex-grow-1 d-flex ">
                                <div class="flex-grow-1 flex-shrink-1" style="max-width: 100%">
                                    <v-form 
                                        ref="form"
                                        style="background-color: #FFFFFF;"
                                        @submit.prevent="enviarFormulario()" 
                                    >
                                        <h5 class="text-uppercase text-h6 font-weight-bold text-center">Iniciar sesión</h5>
                                        <v-divider class="mt-2" />
                                        <v-text-field
                                            v-model="form.correo"
                                            label="Correo electrónico"
                                            placeholder="Ingrese el correo"
                                            class="mt-4"
                                            :error-messages="correoErrors"
                                            :disabled="inicioSesion.isLoading"
                                            outlined
                                            @change="$v.form.correo.$touch()"
                                            @blur="$v.form.correo.$touch()"
                                        />
                                        <v-text-field
                                            v-model="form.password"
                                            :type="verPassword ? 'text' : 'password'"
                                            label="Contraseña"
                                            placeholder="Ingrese la contraseña"
                                            :error-messages="passwordErrors"
                                            :disabled="inicioSesion.isLoading"
                                            outlined
                                            @change="$v.form.password.$touch()"
                                            @blur="$v.form.password.$touch()"
                                        >
                                            <template #append>
                                                <v-icon @click="verPassword = !verPassword">
                                                    {{ verPassword ? 'mdi-eye-off' : 'mdi-eye' }}
                                                </v-icon>
                                            </template>
                                        </v-text-field>
                                        <div class="mt-4 d-flex flex-wrap flex-md-nowrap" style="gap: 16px;">
                                            <v-btn 
                                                class="flex-shrink-1 flex-grow-1" 
                                                color="primary" 
                                                type="submit"
                                                large
                                                :loading="inicioSesion.isLoading"
                                            >
                                                Iniciar sesión
                                            </v-btn>
                                        </div>
                                    </v-form>
                                </div>
                            </div>
                            <!-- Finaliza contenido lado izquierdo -->
                            
                            <v-divider vertical class="flex-shrink-0" />
                            <v-divider class="d-md-none" />


                            <!-- Inicia contenido lado derecho -->
                            <div
                                class="login-right-content px-4 py-4 pt-md-0 flex-grow-1"
                                style="flex-basis: 225px !important"
                            >
                                <div style="max-width: 100%">
                                    <div style="flex-basis: 225px !important">
                                        <div class="d-flex flex-column px-4 py-4" style="gap: 16px;">
                                            <v-btn
                                                class="text-subtitle-2 text-uppercase boton-accion"
                                                text
                                                block
                                                @click.stop="abrirModalRestablecerPassword"
                                            >
                                                Restablecer contraseña
                                            </v-btn>
    
                                            <v-btn 
                                                class="flex-shrink-1 flex-grow-1 boton-accion" 
                                                color="primary" 
                                                text
                                                block
                                                to="/registro-procesos-compra"
                                            >
                                                Registrarse
                                            </v-btn>

                                            <v-btn 
                                                class="flex-shrink-1 flex-grow-1 boton-accion" 
                                                color="primary" 
                                                text
                                                block
                                                to="/login"
                                            >
                                                Volver a COMPRASAL
                                            </v-btn>
                                        </div>
                                    </div>
                                </div>
                            </div>  
                            <!-- Finaliza contenido lado derecho -->
                    </div>
                    </v-card>
                </v-flex>
                <ModalRestablecerPassword  :is-open.sync="modalRestablecerPasswordAbierta" />
            </v-container>
        </v-main>
    </v-app>
</template>
<script>
import { createLoadable, isResponseSuccesful, setLoadableResponse, toggleLoadable } from '@/utils/loadable';
import ModalRestablecerPassword from './components/ModalRestablecerPassword.vue';
import { required, helpers } from 'vuelidate/lib/validators';
import NavButton from '@/components/utils/NavButton.vue';
import { LocalStorage } from '@/utils/LocalStorage.mjs';

const initialFormState = {
    correo: null,
    password: null,
};

const correo = helpers.regex(
  "email",
  /^[a-zA-Z\d]+[a-zA-Z\d._-]*@([a-zA-Z\d]+(-|.)?){0,3}[a-zA-Z\d]+(\.[a-zA-Z\d]+)?(\.[a-zA-Z\d]{2,})$/
);

export default {
    components: { NavButton, ModalRestablecerPassword },
    validations: {
        form: {
            correo: { required, correo },
            password: { required },
        },
    },
    data: () => ({
        form: { ...initialFormState },
        modalRestablecerPasswordAbierta: false,
        verPassword: false,
        // DATA
        inicioSesion: createLoadable(null),
    }),
    computed: {
        correoErrors() {
            const errors = [];
            if (!this.$v.form.correo.$dirty) return errors;

            !this.$v.form.correo.required && errors.push("El correo es requerido.");
            !this.$v.form.correo.correo && errors.push("El correo debe ser válido.");

            return errors;
        },
        passwordErrors() {
            const errors = [];
            if (!this.$v.form.password.$dirty) return errors;

            !this.$v.form.password.required && errors.push("La contraseña es requerida.");

            return errors;
        },
    },
    methods: {
         // UI
         limpiarFormulario() {
            this.$v.$reset();
            this.$refs.form.reset();
            this.form = { ...initialFormState };
        },
        enviarFormulario() {
            this.$v.$touch();

            if (this.$v.form.$invalid) return;

            this.iniciarSesion();
        },
        abrirModalRestablecerPassword() {
            this.modalRestablecerPasswordAbierta = true;
        },
        // DATA
        async iniciarSesion() {
            toggleLoadable(this.inicioSesion);
            const { data } = await this.services.RegistroCiudadanos.iniciarSesion({ ...this.form });
            setLoadableResponse(this.inicioSesion, data, { showAlertOnSuccess: true, skipOnSuccess: true });

            if (isResponseSuccesful(data)) {
                this.limpiarFormulario();
                LocalStorage.token_procesos_libres = data.data;
                this.$router.push('/procesos-publicos');
            }
        },
    },
    mounted() {
        if (LocalStorage.token_procesos_libres) {
            this.$router.push('/procesos-publicos');
        }
    },
}
</script>
<style scoped>
.bg-login {
    background-image: url("../../assets/img/fondoLogin.jpeg") !important;
    background-size: cover !important;
    background-position: center top !important;
    background-repeat: no-repeat !important;
    position: relative;
  }

  .bg-login::after {
    content: "";
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    position: absolute;
    left: 0;
    top: 0;
  }

  @media (min-width: 960px) {
    :deep(.login-right-content) {
      border-bottom-right-radius: 5px;
      border-top-right-radius: 5px;
      border-bottom-left-radius: 0;
      overflow: hidden !important;
      background-color: #313945 !important;
    }

    :deep(.boton-accion) {
        color: #FFF !important;
    }
}
</style>